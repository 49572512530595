.term-policy-modal {
  width: 600px !important;
}
.term-policy-modal .term-container {
  position: relative;
}
.term-policy-modal .term-container .term-header .ant-divider-inner-text {
  font-weight: 500;
  font-size: 26px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: #24292f;
}
.term-policy-modal .term-container .term-content {
  margin-block: 1.5rem;
  padding-block: 0.5rem;
  background-color: #fcfbfc;
  position: relative;
}
.term-policy-modal .term-container .term-content .text {
  max-height: 50vh;
  overflow-y: auto;
  padding-inline: 1rem;
  position: relative;
}
.term-policy-modal .term-container .term-content .shadow {
  pointer-events: none;
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 35px;
  background: #ffffff;
  background: -moz-linear-gradient(0deg, #ffffff 0%, rgba(255, 255, 255, 0) 77%);
  background: -webkit-linear-gradient(0deg, #ffffff 0%, rgba(255, 255, 255, 0) 77%);
  background: linear-gradient(0deg, #ffffff 0%, rgba(255, 255, 255, 0) 77%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff", endColorstr="#ffffff", GradientType=1);
}
.term-policy-modal .term-container .term-footer {
  display: flex;
  justify-content: center;
  gap: 2rem;
}
.term-policy-modal .term-container .term-footer .accept-btn {
  appearance: none;
  background-color: #2ea44f;
  border: 1px solid rgba(27, 31, 35, 0.15);
  border-radius: 6px;
  box-shadow: rgba(27, 31, 35, 0.1) 0 1px 0;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: -apple-system, system-ui, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  padding: 6px 60px;
  position: relative;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
  white-space: nowrap;
}
.term-policy-modal .term-container .term-footer .accept-btn:focus:not(:focus-visible):not(.focus-visible) {
  box-shadow: none;
  outline: none;
}
.term-policy-modal .term-container .term-footer .accept-btn:hover {
  background-color: #2c974b;
}
.term-policy-modal .term-container .term-footer .accept-btn:focus {
  box-shadow: rgba(46, 164, 79, 0.4) 0 0 0 3px;
  outline: none;
}
.term-policy-modal .term-container .term-footer .accept-btn:disabled {
  background-color: #94d3a2;
  border-color: rgba(27, 31, 35, 0.1);
  color: rgba(255, 255, 255, 0.8);
  cursor: default;
}
.term-policy-modal .term-container .term-footer .accept-btn:active {
  background-color: #298e46;
  box-shadow: rgba(20, 70, 32, 0.2) 0 1px 0 inset;
}
.term-policy-modal .term-container .term-footer .decline-btn {
  appearance: none;
  background-color: #fafbfc;
  border: 1px solid rgba(27, 31, 35, 0.15);
  border-radius: 6px;
  box-shadow: rgba(27, 31, 35, 0.04) 0 1px 0, rgba(255, 255, 255, 0.25) 0 1px 0 inset;
  box-sizing: border-box;
  color: #24292e;
  cursor: pointer;
  display: inline-block;
  font-family: -apple-system, system-ui, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  list-style: none;
  padding: 6px 60px;
  position: relative;
  transition: background-color 0.2s cubic-bezier(0.3, 0, 0.5, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
  white-space: nowrap;
  word-wrap: break-word;
}
.term-policy-modal .term-container .term-footer .decline-btn:hover {
  background-color: #f3f4f6;
  text-decoration: none;
  transition-duration: 0.1s;
}
.term-policy-modal .term-container .term-footer .decline-btn:disabled {
  background-color: #fafbfc;
  border-color: rgba(27, 31, 35, 0.15);
  color: #959da5;
  cursor: default;
}
.term-policy-modal .term-container .term-footer .decline-btn:active {
  background-color: #edeff2;
  box-shadow: rgba(225, 228, 232, 0.2) 0 1px 0 inset;
  transition: none 0s;
}
.term-policy-modal .term-container .term-footer .decline-btn:focus {
  outline: 1px transparent;
}
.term-policy-modal .term-container .term-footer .decline-btn:before {
  display: none;
}
.term-policy-modal .term-container .term-footer .decline-btn:-webkit-details-marker {
  display: none;
}
.term-policy-modal .nice-scroll {
  scrollbar-width: thin;
}
.term-policy-modal .nice-scroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  border-radius: 0px;
  background-color: #f5f5f5;
}
.term-policy-modal .nice-scroll::-webkit-scrollbar-thumb {
  border-radius: 0;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  background-color: #a7a7a7;
}
.term-policy-modal .nice-scroll::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: #f5f5f5;
}
