.test-conversation-page .test-conversation-page-row .conversation-col {
  padding: 15px;
}
.test-conversation-page .test-conversation-page-row .conversation-col .conversation-col-container {
  margin: auto;
}
.test-conversation-page .test-conversation-page-row .conversation-col .conversation-col-container .play-button-section {
  height: 35px;
  display: flex;
  padding-bottom: 5px;
}
.test-conversation-page .test-conversation-page-row .conversation-col .conversation-col-container .play-button-section a {
  align-self: flex-end;
  font-size: 15px;
  margin-left: auto;
  color: black;
  font-weight: 500;
}
.test-conversation-page .test-conversation-page-row .conversation-col .conversation-col-container .play-button-section a i {
  margin-right: 5px;
}
.test-conversation-page .test-conversation-page-row .conversation-col .conversation-col-container .conversation-player {
  height: calc(100vh - 200px);
}
.test-conversation-page .test-conversation-page-row .conversation-col .conversation-col-container .message-section {
  height: calc(100vh - 200px);
  border: 2px solid;
  line-height: calc(100vh - 200px);
  text-align: center;
}
.test-conversation-page .test-conversation-page-row .conversation-col .conversation-col-container .message-section span {
  font-size: 20px;
  font-weight: bold;
}
.test-conversation-page .test-conversation-page-row .trace-col {
  padding: 15px;
}
