.extended-subscription-notice-modal .extended-subscription-container {
  position: relative;
}
.extended-subscription-notice-modal .extended-subscription-container .extended-subscription-header .ant-divider-inner-text {
  font-weight: 500;
  font-size: 26px;
  color: #24292f;
}
.extended-subscription-notice-modal .extended-subscription-container .extended-subscription-content {
  margin-block: 1.5rem;
  padding-block: 0.5rem;
  position: relative;
}
.extended-subscription-notice-modal .extended-subscription-container .extended-subscription-content-list {
  margin-top: 12px;
}
.extended-subscription-notice-modal .extended-subscription-container .extended-subscription-footer {
  display: flex;
  justify-content: flex-end;
  gap: 2rem;
}
.extended-subscription-notice-modal .extended-subscription-container .extended-subscription-footer .decline-btn {
  appearance: none;
  background-color: #fafbfc;
  border: 1px solid rgba(27, 31, 35, 0.15);
  border-radius: 6px;
  box-shadow: rgba(27, 31, 35, 0.04) 0 1px 0, rgba(255, 255, 255, 0.25) 0 1px 0 inset;
  box-sizing: border-box;
  color: #24292e;
  cursor: pointer;
  display: inline-block;
  font-family: -apple-system, system-ui, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  list-style: none;
  padding: 6px 60px 0;
  position: relative;
  transition: background-color 0.2s cubic-bezier(0.3, 0, 0.5, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
  white-space: nowrap;
  word-wrap: break-word;
}
.extended-subscription-notice-modal .extended-subscription-container .extended-subscription-footer .decline-btn:hover {
  background-color: #f3f4f6;
  text-decoration: none;
  transition-duration: 0.1s;
}
.extended-subscription-notice-modal .extended-subscription-container .extended-subscription-footer .decline-btn:disabled {
  background-color: #fafbfc;
  border-color: rgba(27, 31, 35, 0.15);
  color: #959da5;
  cursor: default;
}
.extended-subscription-notice-modal .extended-subscription-container .extended-subscription-footer .decline-btn:active {
  background-color: #edeff2;
  box-shadow: rgba(225, 228, 232, 0.2) 0 1px 0 inset;
  transition: none 0s;
}
.extended-subscription-notice-modal .extended-subscription-container .extended-subscription-footer .decline-btn:focus {
  outline: 1px transparent;
}
.extended-subscription-notice-modal .extended-subscription-container .extended-subscription-footer .decline-btn:before {
  display: none;
}
.extended-subscription-notice-modal .extended-subscription-container .extended-subscription-footer .decline-btn::-webkit-details-marker {
  display: none;
}
