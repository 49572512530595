/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-dropdown-menu-item > a.gl-link.active {
  font-weight: bold;
  color: #1890ff;
  pointer-events: none;
  cursor: pointer;
}
.ant-dropdown-menu-item > a.gl-link.active i {
  color: #1890ff;
}
