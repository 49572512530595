.wrapper {
  position: absolute;
  width: 35px;
  z-index: 9;
  display: flex;
  justify-content: right;
  bottom: 50px;
  right: 130px;
  background-color: lightgray;
  padding: 2px 0 2px 0;
}
.wrapper .base {
  display: none;
}
.wrapper .base ul {
  position: absolute;
  bottom: 25px;
  right: 10px;
  margin: 4px 0;
  padding: 4px 0;
  text-align: left;
  list-style-type: none;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 4px;
  outline: none;
  -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  -webkit-transform: translate3d(0, 0, 0);
}
.wrapper .base .container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2px 6px;
}
.wrapper .base .container * {
  margin: 0 4px;
}
.wrapper .base .container span {
  display: inline;
  white-space: nowrap;
}
.wrapper .base .container .item-title {
  width: 60px;
}
.wrapper .base .container svg {
  width: 24px;
  height: 24px;
}
.wrapper .base .container svg .text {
  fill: white;
  font-size: 12px;
}
.wrapper .base .switch {
  display: flex;
}
.wrapper .base .switch .menu-button svg {
  width: 40px;
  height: 32px;
}
.wrapper .base .switch .menu-button.disable .path {
  fill: gray !important;
}
