.flat-button {
  width: 23px;
  height: 18px;
  margin: 2px 6px;
  display: flex;
  place-content: center;
  cursor: pointer;
}
.flat-button svg {
  height: 100%;
}
.contentplayer:-webkit-full-screen {
  width: 100vw;
  height: 100vh;
  background-color: black;
}
.contentplayer:-webkit-full-screen .caption-layer .word {
  color: white;
}
.contentplayer:-moz-full-screen {
  width: 100vw;
  height: 100vh;
  background-color: black;
}
.contentplayer:-moz-full-screen .caption-layer .word {
  color: white;
}
.contentplayer.ios9-full-screen {
  width: 100vw;
  height: 100vh;
  background-color: black;
}
.contentplayer.ios9-full-screen .caption-layer .word {
  color: white;
}
.contentplayer:-webkit-full-screen #azuremediaplayer-video {
  background-color: transparent;
}
.contentplayer:-moz-full-screen #azuremediaplayer-video {
  background-color: transparent;
}
.contentplayer.ios9-full-screen #azuremediaplayer-video {
  background-color: transparent;
}
@media screen and (min-width: 1024px) and (min-height: 768px) {
  .contentplayer:-webkit-full-screen .caption-layer .caption-container .caption > div .layer > span {
    font-size: 36px;
  }
  .contentplayer:-moz-full-screen .caption-layer .caption-container .caption > div .layer > span {
    font-size: 36px;
  }
  .contentplayer.ios9-full-screen .caption-layer .caption-container .caption > div .layer > span {
    font-size: 36px;
  }
}
