.subtitle-layer {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  max-width: 1024px;
  height: 100%;
  margin: 0 auto;
  justify-content: center;
  align-items: flex-start;
  pointer-events: none;
  overflow: hidden;
  visibility: visible;
  opacity: 1;
}
.subtitle-layer.hidden {
  visibility: hidden;
  opacity: 0;
}
.subtitle-layer .subtitle-container {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  overflow: hidden;
  transition: height 0.3s ease-out 0s;
}
.subtitle-layer .subtitle-container .subtitle {
  display: flex;
  flex-direction: column;
  font-family: 'Didact Gothic', sans-serif;
  transition: transform 0.3s ease-out 0s;
  visibility: visible;
  opacity: 1;
}
.subtitle-layer .subtitle-container .subtitle.hidden-ends {
  visibility: hidden;
  opacity: 0;
  transition: all 3s ease-out 0s;
}
.subtitle-layer .subtitle-container .subtitle .line {
  font-size: 22px;
  position: relative;
  margin-bottom: 10px;
  line-height: 36px;
  min-height: 36px;
  padding: 0 20px;
  visibility: hidden;
  opacity: 0;
}
.subtitle-layer .subtitle-container .subtitle .line.show {
  visibility: visible;
  opacity: 1;
}
.subtitle-layer .subtitle-container .subtitle .line.show-gentle {
  visibility: visible;
  opacity: 1;
  transition: all 0.3s ease-out 0s;
}
.subtitle-layer .subtitle-container .subtitle .line.hidden-gentle {
  transform: translateY(calc(-100%));
  visibility: hidden;
  opacity: 0;
  transition: all 1s ease-out 1s;
}
.subtitle-layer .subtitle-container .subtitle .line .speaker-voice {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: 7px;
  left: 1px;
  font-size: 18px;
}
.subtitle-layer .subtitle-container .subtitle .line .speaker-voice-show {
  visibility: visible;
  opacity: 1;
}
.subtitle-layer .subtitle-container .subtitle .speak1 {
  color: #000;
  background-color: rgba(217, 208, 203, 0.733);
}
.subtitle-layer .subtitle-container .subtitle .speak2 {
  color: #fff;
  background-color: rgba(113, 183, 120, 0.733);
}
.subtitle-layer .subtitle-container .subtitle .speak3 {
  color: #fff;
  background-color: rgba(129, 145, 193, 0.733);
}
.subtitle-layer .subtitle-container .subtitle .speak4 {
  color: #fff;
  background-color: rgba(220, 148, 116, 0.733);
}
.subtitle-layer .subtitle-container .subtitle .speak5 {
  color: #fff;
  background-color: rgba(166, 135, 178, 0.733);
}
