.dropdown-wrapper {
  position: relative;
  width: 35px;
}
.dropdown-wrapper .dropdown-base {
  display: none;
}
.dropdown-wrapper .dropdown-base ul {
  position: absolute;
  bottom: 25px;
  right: 10px;
  margin: 4px 0;
  padding: 4px 0;
  text-align: left;
  list-style-type: none;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 4px;
  outline: none;
  -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  -webkit-transform: translate3d(0, 0, 0);
}
.dropdown-wrapper .dropdown-base .dropdown-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2px 6px;
}
.dropdown-wrapper .dropdown-base .dropdown-container * {
  margin: 0 4px;
}
.dropdown-wrapper .dropdown-base .dropdown-container span {
  display: inline;
  white-space: nowrap;
}
.dropdown-wrapper .dropdown-base .dropdown-container .dropdown-item-title {
  width: 60px;
}
.dropdown-wrapper .dropdown-base .dropdown-container svg {
  width: 24px;
  height: 24px;
}
.dropdown-wrapper .dropdown-base .dropdown-container svg .text {
  fill: white;
  font-size: 12px;
}
.dropdown-wrapper .dropdown-base .switch-base {
  display: flex;
}
.dropdown-wrapper .dropdown-base .switch-base .menu-button svg {
  width: 40px;
  height: 32px;
}
.dropdown-wrapper .dropdown-base .switch-base .menu-button.disable .path {
  fill: gray !important;
}
