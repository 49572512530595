.playlist-reward-card {
  position: relative;
}
.playlist-reward-card .content-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.playlist-reward-card .reward-badge {
  width: 160px;
  height: 160px;
  cursor: pointer;
}
.playlist-reward-card .puzzle-wrapper {
  flex: 1;
  padding: 16px;
}
.playlist-reward-card .puzzle-image {
  max-width: 100%;
  border: solid 2px #541b86;
}
.playlist-reward-card .btn-close {
  position: absolute;
  top: -20px;
  right: -20px;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  color: white;
  background-color: #ff4d4f;
  border: none;
}
