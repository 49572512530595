/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.tw-actions {
  display: flex;
  align-items: center;
  height: 42px;
}
.tw-actions.no-height {
  height: auto;
}
.tw-actions > .tw-action-item {
  line-height: normal;
  display: flex;
  align-items: center;
  user-select: none;
}
.tw-actions > .tw-action-item.cursor-pointer {
  cursor: pointer;
}
.tw-actions > .tw-action-item .tw-action-text {
  color: #1890ff;
  opacity: 0.9;
  transition: all 0.2s;
  line-height: 22px;
}
.tw-actions > .tw-action-item > i {
  color: rgba(0, 0, 0, 0.45);
  font-size: 22px;
  transition: all 0.2s;
}
.tw-actions > .tw-action-item.has-text > i {
  opacity: 0.5;
}
.tw-actions > .tw-action-item > i + .tw-action-text {
  margin-left: 5px;
}
.tw-actions > .tw-action-item.cursor-pointer:hover:not(.tw-action-item-disabled) > i,
.tw-actions > .tw-action-item.cursor-pointer:focus:not(.tw-action-item-disabled) > i {
  color: rgba(0, 0, 0, 0.65);
}
.tw-actions > .tw-action-item.cursor-pointer:hover:not(.tw-action-item-disabled) .tw-action-text,
.tw-actions > .tw-action-item.cursor-pointer:focus:not(.tw-action-item-disabled) .tw-action-text {
  opacity: 1;
}
.tw-actions > .tw-action-item.tw-action-item-disabled {
  opacity: 0.7;
  cursor: not-allowed;
}
.tw-actions > .tw-action-item + .tw-action-item {
  margin-left: 15px;
}
