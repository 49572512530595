.cnv-pending {
  margin-left: 15px;
}
.cnv-pending__icon {
  width: 45px;
  height: 50px;
  display: block;
  border-radius: 50%;
}
.cnv-pending .ant-badge-count {
  top: 10px;
}
.cnv-pending--inside-dropdown {
  padding: 0 !important;
  border: none !important;
}
.cnv-pending--inside-dropdown .cnv-pending__icon {
  border-radius: 0;
}
.cnv-pending--inside-dropdown .ant-badge-count {
  right: -23px;
  top: 50%;
}
.cnv-pending__list--item {
  align-items: center;
}
.cnv-pending__list--item .ant-list-item-meta-avatar {
  margin-right: 30px;
}
.cnv-pending__list--itemicon {
  width: 55px;
  height: 55px;
}
.cnv-pending__list--playericon {
  font-size: 24px;
}
.ant-popover-inner {
  max-height: 80vh;
  overflow: auto;
}
