.header-notification-container {
  width: 450px;
  height: unset;
  max-height: 50vh;
  background-color: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  position: absolute;
  z-index: 98;
  top: 56px;
  right: 0px;
  padding: 20px 10px 20px 20px;
  display: flex;
  flex-direction: column;
}
.header-notification-container .header-notification-function {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header-notification-container .header-notification-list {
  flex: 1 1 0%;
  overflow: auto;
  margin: 0.5rem 0px;
}
.header-notification-container .header-notification-list .header-notification-item-title {
  display: inline;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 500;
  cursor: pointer;
}
.header-notification-container .header-notification-list .header-notification-item-description {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media (max-width: 1200px) {
  .header-notification-container {
    width: 100%;
  }
}
