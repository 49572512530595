.focuswordcontent {
  display: flex;
  flex: 4;
  flex-direction: column;
  border-radius: 14px;
  margin-left: 20px;
}
.focuswordcontent__header {
  display: flex;
  height: 20%;
  justify-content: center;
  align-items: center;
  font-size: 40px;
}
.focuswordcontent__maincontent {
  height: 100%;
}
.focuswordcontent__definition {
  display: flex;
  justify-content: center;
  font-size: 18px;
  height: 10%;
  align-items: baseline;
}
.focuswordcontent__image {
  width: 100%;
  height: 85%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.focuswordcontent__image img {
  width: 45%;
  aspect-ratio: 1;
  object-fit: cover;
}
.focuswordcontent__controls {
  display: flex;
  flex-direction: row;
  height: 10%;
  align-items: center;
  position: absolute;
  right: 34px;
}
.focuswordcontent__controls__btn {
  cursor: pointer;
  height: 45px;
  aspect-ratio: 1;
  margin: 0px 10px;
}
.focuswordcontent__controls__btn--disabled {
  opacity: 0.7;
  cursor: not-allowed;
}
.focuswordcontent__controls__btn--selected {
  color: #018950;
}
.focuswordcontent__controls__btn i {
  font-size: 3rem;
  width: 3rem;
}
.focuswordcontent .btnImage__definition i svg {
  height: 24px;
}
