.focuswords {
  height: 100%;
  background: white;
  display: flex;
  justify-content: start;
}
.focuswords__btn {
  display: flex;
  align-items: center;
  z-index: 10;
}
.focuswords__btn--left {
  cursor: pointer;
}
.focuswords__btn--left i {
  font-size: 2rem;
}
.focuswords__btn--leftdisabled {
  cursor: not-allowed;
  opacity: 0.7;
}
.focuswords__btn--leftdisabled i {
  font-size: 2rem;
}
.focuswords__btn--right {
  cursor: pointer;
}
.focuswords__btn--right i {
  font-size: 2rem;
}
.focuswords__container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-grow: 2;
}
