/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.sw-loader {
  text-align: center;
  padding: 20px 0;
}
.sw-loader__icon {
  color: #1890ff;
  font-size: 30px;
}
.sw-loader__text {
  margin: 0;
  color: rgba(0, 0, 0, 0.45);
  font-size: 13px;
  font-style: italic;
  margin-top: 10px;
}
.sw-loader--cover {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
}
