/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.wgrid__pagination {
  margin-top: 10px;
  text-align: right;
}
.wgrid__nodata .ant-empty-description {
  margin: 12px 0;
}
.wgrid__grid {
  vertical-align: top;
}
.wgrid__wrapper {
  position: relative;
}
.wgrid__loader {
  position: absolute;
  width: 100%;
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.5);
}
.wgrid__head {
  margin-bottom: 10px;
}
.wgrid__head__left-column {
  max-width: 100%;
}
.wgrid__title {
  margin-top: 15px;
  margin-bottom: 0;
}
.wgrid__search {
  display: inline-block;
  width: 360px;
  max-width: 100%;
}
.wgrid__search .wj-flexgridsearch {
  border-radius: 20px;
}
.wgrid__search .wj-flexgridsearch .wj-input .wj-input-group .wj-form-control {
  min-height: auto;
}
.wgrid__search .wj-flexgridsearch .wj-input .wj-input-group .wj-btn {
  font-size: 22px;
  line-height: 24px;
  height: 24px;
  max-height: 24px;
  min-height: auto;
  color: #a8a8a8;
}
.wgrid__search .ant-input {
  width: 100% !important;
}
.wgrid__state {
  margin-top: 15px;
}
.wgrid .wj-flexgrid {
  border: none;
}
.wgrid .wj-state-disabled {
  opacity: 1;
}
.wgrid .wj-state-disabled:not(.wj-state-selected):not(.wj-state-multi-selected) {
  background: #f1f1f1 !important;
}
.wgrid .wj-state-disabled .wj-cell-check {
  display: none;
}
.wgrid .wj-cell-check {
  pointer-events: auto;
}
.wgrid .wj-cell.wj-frozen {
  background: #fff;
}
.wj-header {
  color: rgba(0, 0, 0, 0.85) !important;
  font-weight: 500 !important;
}
.wj-header:not(.wj-state-invalid) {
  background: #fafafa !important;
  border-bottom: 1px solid #e8e8e8 !important;
}
.wj-cell {
  line-height: 32px;
}
.wj-cell.wj-wrap:not(.wj-header) {
  line-height: 1.4;
  padding-top: 8px;
  padding-bottom: 8px;
}
.wj-cell:not(.wj-state-invalid) {
  border-color: #e8e8e8;
  border-right: none !important;
  border-bottom: 1px solid #e8e8e8 !important;
}
.wj-cell ::-webkit-scrollbar {
  width: 2em;
  height: 2em;
}
.wj-cell > div {
  height: 100%;
}
.wj-state-selected {
  background: #e5eee8 !important;
  color: rgba(0, 0, 0, 0.85) !important;
}
.wj-state-multi-selected {
  background: #e5eee8 !important;
  color: rgba(0, 0, 0, 0.85) !important;
}
.wj-input-group .wj-btn {
  border: none !important;
}
.wj-input-group .wj-btn:hover {
  background: transparent !important;
}
.wj-input-group:hover {
  border-color: #1890ff !important;
}
.wj-input-group:focus {
  border-color: #1890ff !important;
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}
.wj-grouppanel {
  background: #f2f2f2;
  margin-top: 5px;
  border-bottom: 1px solid #e8e8e8;
  margin-top: 0px;
}
div.search-section input.ant-input {
  width: 100% !important;
  border-radius: 5px !important;
  padding: 10px !important;
}
.wj-state-sticky .wj-header.wj-cell {
  opacity: 1 !important;
  border-bottom: 2px solid #ddd;
}
.wgrid--bordered .wj-cell:not(.wj-state-invalid) {
  border-right: 1px solid #e8e8e8 !important;
}
.wgrid--bordered .wj-flexgrid {
  vertical-align: top;
}
.wgrid--bordered .wgrid__wrapper {
  border: 1px solid #e8e8e8;
}
