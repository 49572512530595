.conversationlist__heading {
  margin-left: 10px;
  margin-top: 30px;
}
.conversationlist__grid {
  margin: 0 10px 0 10px;
}
.conversationlist__grid--icon {
  display: flex;
  justify-content: center;
}
.conversationlist__grid--playicon {
  width: 35px;
  height: 35px;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
