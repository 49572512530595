.pid {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  column-gap: 4px;
}
.pid__item {
  height: 16px;
  background-color: #e4e4e4;
}
.pid__item:first-child {
  border-radius: 10px 0 0 10px;
}
.pid__item:last-child {
  border-radius: 0 10px 10px 0;
}
.pid--active {
  background-color: #69ce2b;
}
.mpd {
  text-align: center;
  padding: 10px 0;
}
.mpd .material-icons {
  font-size: 84px;
  color: #faad14;
}
