.fwlist {
  height: 100%;
  flex: 1;
  overflow: auto;
}
.fwlist__fwlistItem {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1em 0px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  border-radius: 14px;
  margin-top: 10px;
  background-color: #d5e5f0;
  color: black;
}
.fwlist__fwlistItem:first-child {
  margin-top: 0;
}
.fwlist__fwlistItem--selected {
  background-color: #e15132;
  color: white;
}
