.conv-intro-player {
  width: 90vw !important;
  height: 80vh !important;
}
.conv-intro-player .ant-modal-close {
  border-radius: 50%;
  border: 1px solid black;
  right: 10px;
  top: 15px;
  height: 30px;
  width: 30px;
}
.conv-intro-player .ant-modal-close .ant-modal-close-x {
  color: black;
  display: inline;
  line-height: 0px;
}
.conv-intro-player .ant-modal-header {
  height: 50px;
}
.conv-intro-player .video-section {
  width: inherit;
  height: calc(80vh - 50px);
}
.conv-intro-player .video-section > video {
  width: 100%;
  height: inherit;
}
