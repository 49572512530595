.readerchapter {
  display: flex;
  justify-content: start;
  height: inherit;
}
.readerchapter__pageplay {
  flex-grow: 2;
  display: flex;
}
.readerchapter__pagebtn {
  background: white;
  display: flex;
  align-items: center;
}
.readerchapter__pagebtn--left {
  cursor: pointer;
  z-index: 10;
}
.readerchapter__pagebtn--left i {
  font-size: 2rem;
}
.readerchapter__pagebtn--leftdisabled {
  cursor: not-allowed;
  opacity: 0.7;
}
.readerchapter__pagebtn--leftdisabled i {
  font-size: 2rem;
}
.readerchapter__pagebtn--right {
  cursor: pointer;
  z-index: 10;
}
.readerchapter__pagebtn--right i {
  font-size: 2rem;
}
.readerchapter-page {
  width: 95%;
}
.readerchapter-play {
  background: white;
  width: 5%;
}
.readerchapter-play--icon {
  cursor: pointer;
  margin-left: 10px;
  display: inline-block;
  z-index: 10;
  position: absolute;
}
.readerchapter-play--icon i {
  font-size: 32px;
}
.readerchapter-play--icondisabled {
  cursor: not-allowed;
  opacity: 0.7;
  margin-left: 10px;
  display: inline-block;
  z-index: 10;
  position: absolute;
}
.readerchapter-play--icondisabled i {
  font-size: 32px;
}
